<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div v-if="hasAbonnement">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
          <div class="">
            <animal :animal-id="animal.uid" />
          </div>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
          <div class="row">
            <div class="col-5">
              <div class="widget widget-warning mb-3">
                <div class="widget-content">
                  <div class="row">
                    <div class="col-3">
                      <img
                        v-if="proprietaire.photo"
                        :src="JSON.parse(proprietaire.photo).url"
                        alt="Photo de l'utilisateur"
                        class="img-thumbnail"
                      >
                      <span
                        v-else
                        style="border: 1px solid rgba(0,0,0,0.15); border-radius: 50%;"
                      ><i
                        class="las la-user la-6x"
                        style="color: #ccc"
                      /></span>
                    </div>
                    <div class="col-9 pl-4">
                      <p class="card-text">
                        <strong>Nom Complet:</strong> {{ proprietaire.fullname }}
                      </p>
                      <p class="card-text">
                        <strong>Email:</strong> {{ proprietaire.email }}
                      </p>
                      <p class="card-text">
                        <strong>Téléphone:</strong> {{ proprietaire.telephone }}
                      </p>
                      <p class="card-text">
                        <strong>Dernière Connexion:</strong> {{ formatDate(proprietaire.lastConnexion) }}
                      </p>
                      <p class="card-text">
                        <strong>Actif:</strong> <span :class="{'text-success': proprietaire.active, 'text-danger': !proprietaire.active}">{{ proprietaire.active ? 'Oui' : 'Non' }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="widget mb-3">
                <div class="widget-content">
                  <h5 class="card-title" />
                  <div class="row">
                    <div class="col-5">
                      <div>
                        <img
                          width="70"
                          height="70"
                          :src="JSON.parse(pack.photo).url"
                          v-if="pack.photo"
                          style="border-radius: 50%; display: block;"
                        >
                        <span style="font-size: 1rem; font-weight: 500; color: rgba(0,0,0,0.75);">{{ pack.libelle }}</span>
                      </div>
                      <div style="padding: 10px; font-size: 14px; font-weight: 600; color: rgba(0,0,0,0.56)">
                        Modules
                      </div>
                      <div>
                        <module
                          v-for="m in parsedModules"
                          :key="m"
                          :module-uid="m"
                        />
                      </div>
                    </div>
                    <div class="col-7">
                      <p class="card-text">
                        <strong>Montant: </strong> {{ formatCurrency(abonnement.amount) }}
                      </p>
                      <p class="card-text">
                        <strong>Dernier Renouvellement: </strong> {{ abonnement.lastRenew |showTime }}
                      </p>
                      <p class="card-text">
                        <strong>Actif: </strong> 
                        <i
                          class="icofont icofont-check text-success icofont-2x"
                          v-if="abonnement.actif"
                        />
                        <i
                          class="icofont icofont-close text-danger icofont-2x"
                          v-else
                        />
                      </p>
                      <p class="card-text">
                        <strong>Date d'abonnement: </strong> {{ abonnement.createdAt|showTime }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="widget">
                <div class="widget-header">
                  <h5>Historique des renouvellements </h5>
                </div>
                <div class="widget-content">
                  <div class="row">
                    <div
                      class="col-12"
                      v-if="abonnementHistoriques.length > 0"
                    >
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Facture</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(h, index) in abonnementHistoriques"
                            :key="index"
                          > 
                            <td>{{ index+1 }}</td>
                            <td>{{ h.createdAt|showTime }}</td>
                            <td>{{ h }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="col-12"
                      v-else
                    >
                      <p class="alert alert-danger">
                        Pas de  renouvellements enregistrés
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="alert alert-danger">
        Abonnement non trouvé
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '../../components/navbar.vue';
import Animal from '../../components/identification/animal/animalVerticalDetail.vue'
import Module from '../../components/pack/abonnement/detail/module.vue'
export default {
    components: { Navbar, Animal, Module },
    data(){
        return {
            navbarItems: [
                {
                    libelle: 'Abonements',
                    route: 'pack-abonnements'
                },
                {
                    libelle: 'Détail',
                }
            ],
            pageIcon: 'la-box',
            pageTitle: 'Détail',
            pageDescription: 'Details de l\'abonnement', 
        }
    },

    methods: {
        formatCurrency(amount) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(amount);
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }
  
    },

    computed: {
        ...mapGetters({
            abonnements: 'packs/abonnements',
            historiques: 'packs/renouvellements',
            packs: 'packs/packs',
            animaux: 'identification/animaux',
            proprietaires: 'auth/proprietaires'
        }),
        abonnement(){
            
            return this.abonnements.find(abonnement => abonnement.uid === this.$route.params.uid);
        },

        parsedModules() {
            return JSON.parse(this.abonnement.modules);
        },     

        hasAbonnement(){return this.abonnement !== null && this.abonnement !== undefined},

        animal(){return this.hasAbonnement ? this.animaux.find(item => item.uid === this.abonnement.animal) : null},

        proprietaire(){return this.hasAbonnement ?  this.proprietaires.find(item => item.uid === this.abonnement.user) : null},
        
        pack(){return this.packs.find(item => item.uid === this.abonnement.pack)},

        abonnementHistoriques(){
            return [...this.historiques].filter(item => item.abonnement === this.$route.params.uid)
        }
     }
}
</script>

<style scoped>
strong{
    font-size: 12px;
}
.widget-header {
  padding-bottom: 10px;
  flex:1;
  flex-direction: row;
}
.widget-content {
  padding: 10px 5px;
}
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
.img-thumbnail {
  max-width: 100px;
  margin-top: 10px;
}
</style>