<template>
    <div class="module">
      <i class="las la-angle-double-right"></i>{{ module.libelle }}
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    props: {
      moduleUid: { type: String, required: true },
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        modules: 'packs/modules',
      }),
      module() {
        return this.modules.find((item) => item.uid === this.moduleUid);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .module {
    i {
      margin-right: 5px;
    }
  }
  </style>
  